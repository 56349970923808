<template>
  <div class="videoPlayComponents">
    <div>
      <slot></slot>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="950px"
      class="videoPlayDialog"
      :modal="showModal"
      :destroy-on-close="true"
      append-to-body
    >
      <div style="height: 500px">
        <div v-if="dialogVisible" id="J_prismPlayer" style="height: 100%"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      videoId: "",
    };
  },
  mounted() {},
  methods: {
    async handlePlay() {
      this.dialogVisible = true;
      try {
        let result = await this.$store.dispatch("baseStore/getPlayAuth", {
          aliyun_id: this.videoId,
        });
        if (result.success) {
          var player = new Aliplayer({
            id: "J_prismPlayer",
            width: "100%",
            height: "100%",
            autoplay: true,
            language: this.IS_EN ? "en-us" : "zh-cn",
            vid: result.data.VideoMeta.VideoId,
            playauth: result.data.PlayAuth,
            cover: result.data.VideoMeta.CoverURL,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    startPlay(videoId) {
      // if (JSON.stringify(this.USER_INFO) == "{}") {
      //   this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      //   if (this.PJSource == 10 || this.PJSource == 11) {
      //     this.$router.push("/sign");
      //   }
      //   return;
      // }
      this.videoId = videoId;
      this.handlePlay();
    },
  },
};
</script>

<style lang='less' scoped>
.videoPlayComponents {
  /deep/.videoPlayDialog {
    z-index: 3300 !important;
    // .el-dialog__body {
    //   padding: 0px;
    //   .playbox {
    //     height: 500px;
    //     .prism-player {
    //       height: 100%;
    //     }
    //   }
    // }
  }
}
/deep/.el-dialog__body {
  padding: 0px;
}
</style>
